@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?v7gvhl');
  src:  url('fonts/icomoon.eot?v7gvhl#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?v7gvhl') format('truetype'),
    url('fonts/icomoon.woff?v7gvhl') format('woff'),
    url('fonts/icomoon.svg?v7gvhl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-read-notification:before {
  content: "\e993";
}
.icon-double-check:before {
  content: "\e992";
}
.icon-play:before {
  content: "\e991";
}
.icon-down-arrow:before {
  content: "\e990";
}
.icon-smile:before {
  content: "\e98f";
}
.icon-adicionar:before {
  content: "\e919";
}
.icon-subtrair:before {
  content: "\e98e";
}
.icon-palette:before {
  content: "\e98c";
}
.icon-qr-code:before {
  content: "\e98d";
}
.icon-checked-outline:before {
  content: "\e98b";
}
.icon-abnt:before {
  content: "\e98a";
}
.icon-key:before {
  content: "\e989";
}
.icon-archive:before {
  content: "\e988";
}
.icon-bolt:before {
  content: "\e984";
}
.icon-phases:before {
  content: "\e985";
}
.icon-storage:before {
  content: "\e986";
}
.icon-team:before {
  content: "\e987";
}
.icon-paperclip:before {
  content: "\e983";
}
.icon-history:before {
  content: "\e982";
}
.icon-input:before {
  content: "\e981";
}
.icon-right-arrow:before {
  content: "\e980";
}
.icon-unpin:before {
  content: "\e97f";
}
.icon-share:before {
  content: "\e978";
}
.icon-open-new-tab:before {
  content: "\e979";
}
.icon-lixeira-vazia:before {
  content: "\e97a";
}
.icon-galery:before {
  content: "\e97b";
}
.icon-erase-filter:before {
  content: "\e97c";
}
.icon-copy:before {
  content: "\e97d";
}
.icon-carousel:before {
  content: "\e97e";
}
.icon-coordly:before {
  content: "\e976";
}
.icon-custom-tag:before {
  content: "\e977";
}
.icon-pin:before {
  content: "\e975";
}
.icon-panels:before {
  content: "\e974";
}
.icon-center-right:before {
  content: "\e96d";
}
.icon-top-right:before {
  content: "\e969";
}
.icon-top-left:before {
  content: "\e96a";
}
.icon-top-center:before {
  content: "\e96b";
}
.icon-folder-add:before {
  content: "\e96c";
}
.icon-center-left:before {
  content: "\e96e";
}
.icon-center:before {
  content: "\e96f";
}
.icon-bottom-right:before {
  content: "\e970";
}
.icon-bottom-left:before {
  content: "\e971";
}
.icon-bottom-center:before {
  content: "\e972";
}
.icon-alert:before {
  content: "\e973";
}
.icon-relogio:before {
  content: "\e968";
}
.icon-person:before {
  content: "\e966";
}
.icon-criativy:before {
  content: "\e967";
}
.icon-square-chat:before {
  content: "\e964";
}
.icon-megaphone:before {
  content: "\e965";
}
.icon-adicionar-alt:before {
  content: "\e963";
}
.icon-kanban-collapse:before {
  content: "\e962";
}
.icon-globe:before {
  content: "\e95f";
}
.icon-flag:before {
  content: "\e960";
}
.icon-calendar:before {
  content: "\e961";
}
.icon-list-list:before {
  content: "\e95c";
}
.icon-list-kanban:before {
  content: "\e95d";
}
.icon-list-detailVision:before {
  content: "\e95e";
}
.icon-unbind:before {
  content: "\e95b";
}
.icon-viewpoints-itens:before {
  content: "\e959";
}
.icon-pranchas-itens:before {
  content: "\e95a";
}
.icon-not-visualizar:before {
  content: "\e958";
}
.icon-maleta:before {
  content: "\e957";
}
.icon-camera:before {
  content: "\e956";
}
.icon-ccube:before {
  content: "\e953";
}
.icon-doc-eye:before {
  content: "\e954";
}
.icon-pile-file:before {
  content: "\e955";
}
.icon-cadeado-fechado:before {
  content: "\e952";
}
.icon-viewpoint:before {
  content: "\e950";
}
.icon-avancar-duplo:before {
  content: "\e951";
}
.icon-obsolete-archive:before {
  content: "\e94f";
}
.icon-arquivo-doc:before {
  content: "\e94d";
}
.icon-activities:before {
  content: "\e94e";
}
.icon-crow-cta:before {
  content: "\e947";
}
.icon-icon-excluir-user:before {
  content: "\e94c";
}
.icon-icon-link:before {
  content: "\e94a";
}
.icon-icon-email:before {
  content: "\e94b";
}
.icon-more-actions:before {
  content: "\e948";
}
.icon-estrela-vazia:before {
  content: "\e949";
}
.icon-aviso:before {
  content: "\e946";
}
.icon-atualizar:before {
  content: "\e944";
}
.icon-menu-solicitacoes:before {
  content: "\e945";
}
.icon-viewer-onedrive:before {
  content: "\e943";
}
.icon-sem-conexao:before {
  content: "\e942";
}
.icon-mover:before {
  content: "\e940";
}
.icon-seta-ordenar-asc:before {
  content: "\e93e";
}
.icon-seta-ordenar-desc:before {
  content: "\e93f";
}
.icon-filtro:before {
  content: "\e941";
}
.icon-voltar:before {
  content: "\e93d";
}
.icon-disciplina-ar:before {
  content: "\e93c";
}
.icon-estrela:before {
  content: "\e93b";
}
.icon-visualizar:before {
  content: "\e93a";
}
.icon-erroSenha:before {
  content: "\e929";
}
.icon-checkedSenha:before {
  content: "\e92a";
}
.icon-arroba:before {
  content: "\e91f";
}
.icon-cadeado:before {
  content: "\e91e";
}
.icon-confirmacao:before {
  content: "\e925";
}
.icon-error:before {
  content: "\e926";
}
.icon-informacao:before {
  content: "\e927";
}
.icon-informacao-adicional:before {
  content: "\e928";
}
.icon-expandir:before {
  content: "\e937";
}
.icon-collapse:before {
  content: "\e936";
}
.icon-bolinhaNaoSelecionada:before {
  content: "\e938";
}
.icon-bolinhaSelecionada:before {
  content: "\e939";
}
.icon-adicionar-usuario:before {
  content: "\e91a";
}
.icon-arquivar:before {
  content: "\e91b";
}
.icon-avancar:before {
  content: "\e91c";
}
.icon-cancelar:before {
  content: "\e91d";
}
.icon-confirmar:before {
  content: "\e920";
}
.icon-download:before {
  content: "\e921";
}
.icon-editar:before {
  content: "\e922";
}
.icon-editar-imagem:before {
  content: "\e923";
}
.icon-filtrar:before {
  content: "\e924";
}
.icon-lixeira:before {
  content: "\e92b";
}
.icon-localizacao:before {
  content: "\e92c";
}
.icon-notificacao-convite-aceito:before {
  content: "\e92d";
}
.icon-notificacoes:before {
  content: "\e92e";
}
.icon-pesquisar:before {
  content: "\e92f";
}
.icon-receber:before {
  content: "\e930";
}
.icon-retroceder:before {
  content: "\e931";
}
.icon-sair:before {
  content: "\e932";
}
.icon-solicitar-plotagem:before {
  content: "\e933";
}
.icon-trocar-status:before {
  content: "\e934";
}
.icon-upload:before {
  content: "\e935";
}
.icon-arquivo-2d:before {
  content: "\e900";
}
.icon-arquivo-3d:before {
  content: "\e901";
}
.icon-arquivo-excel:before {
  content: "\e902";
}
.icon-arquivo-img:before {
  content: "\e903";
}
.icon-arquivo-pdf:before {
  content: "\e904";
}
.icon-arquivo-texto:before {
  content: "\e905";
}
.icon-disciplina-arquitetura:before {
  content: "\e906";
}
.icon-disciplina-eletrica:before {
  content: "\e907";
}
.icon-disciplina-estrutura:before {
  content: "\e908";
}
.icon-disciplina-fundacoes:before {
  content: "\e909";
}
.icon-disciplina-hidrossanitarias:before {
  content: "\e90a";
}
.icon-disciplina-incendio:before {
  content: "\e90b";
}
.icon-disciplina-mecanica:before {
  content: "\e90c";
}
.icon-viewer-drive:before {
  content: "\e90d";
}
.icon-viewer-dropbox:before {
  content: "\e90e";
}
.icon-viewer-forge:before {
  content: "\e90f";
}
.icon-viewer-office:before {
  content: "\e910";
}
.icon-menu-ajuda:before {
  content: "\e911";
}
.icon-menu-configuracoes:before {
  content: "\e912";
}
.icon-menu-contexto:before {
  content: "\e913";
}
.icon-menu-gerenciar-usuario:before {
  content: "\e914";
}
.icon-menu-home:before {
  content: "\e915";
}
.icon-menu-loja:before {
  content: "\e916";
}
.icon-menu-obras:before {
  content: "\e917";
}
.icon-menu-plotagem:before {
  content: "\e918";
}
